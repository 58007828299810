import * as React from "react"
import Layout from '../components/Layout'
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Helmet from 'react-helmet';

import "/_custom.scss"

import * as classes from "../components/Site.module.scss"

import LogoGithub from "../images/icon_github.svg";
import LogoLinkedin from "../images/icon_linkedin.svg";
import LogoInstagram from "../images/icon_insta.svg";
import LogoTwitter from "../images/icon_twitter.svg";
import Favicon from "../images/favicon.ico";

const About = () => {
  return (
    <Layout>
      <Helmet>
        <title>Contact - Kevin Blank - kevinblank.com</title>
        <link rel="icon" type="image/x-icon" href={Favicon}></link>
      </Helmet>
      <main>
        <div >
        <Container>
          <Row>
            <Col>
              <h1 className={classes.subPageTitle}>Contact</h1>           
            </Col>
          </Row>
        </Container>
        </div>
        <div>
        <Container>
          <Row>
              <Col />
            <Col style={bigSpacing} md={6}>
                <div style={contentDiv}>
                    <img src={LogoGithub} style={logoStyle} /> <a href="https://github.com/zagnut007">https://github.com/zagnut007</a>
                </div>
                <div style={contentDiv}>
                    <img src={LogoLinkedin} style={logoStyle} /> <a href="https://www.linkedin.com/in/kevin-blank-5901301b/">https://www.linkedin.com/in/kevin-blank-5901301b/</a>
                </div>
                <div style={contentDiv}>
                    <img src={LogoInstagram} style={logoStyle} /> <a href="https://www.instagram.com/kevinblankmaker/">@kevinblankmaker</a>
                </div>
                <div style={contentDiv}>
                    <img src={LogoTwitter} style={logoStyle} /> <a href="https://twitter.com/kevinblank">@kevinblank</a>
                </div>
            </Col>
            <Col />
          </Row>
        </Container>
        </div>
      </main>
    </Layout>
  )
}

export default About

// styles
const bigSpacing = {
    margin: "10px 50px",
}

const logoStyle = {
    width: "40px",
    marginRight: "30px"
}

const contentDiv = {
    marginTop:"30px"
}

const subContentDiv = {
    marginTop:"40px"
}

const subTitle = {
    color: '#666666',
    fontWeight: 100
}

const centerCenter = {
  position: "relative",
  left:" 50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  marginLeft: "6vw"
}